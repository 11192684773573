import SideBar from "../../../components/navbar/sideBar";
import styled from "@emotion/styled";

const MainPage = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  paddingTop: ".5rem",
  position: "absolute",
  // background: "white",
});

const Page = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  marginTop: "-.5rem",
  justifyContent: "center",
  alignItems: "center",
});

const SwordFightingHome = () => {
  return (
    <div>
      <MainPage>
        <SideBar />
        <Page>
          <p>here</p>
        </Page>
      </MainPage>
    </div>
  );
};

export default SwordFightingHome;
