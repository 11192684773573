import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import About from "./pages/about";
import Home from "./pages/home";
import Skills from "./pages/skills";
import Blog from "./pages/blog";
import SwordFightingHome from "./pages/projects/swordFighting/swordHome";
import SwordGear from "./pages/projects/swordFighting/swordGear";
import SwordForms from "./pages/projects/swordFighting/swordForms";
import styled from "@emotion/styled";
import { lightTheme, darkTheme } from "./styles/themes";
import useThemeMode from "./hooks/useThemeMode";
import { ThemeProvider } from "styled-components";
import NavBar from "./components/navbar/NavBar";
import CryptographicCapital from "./pages/projects/cryptographicCapital/cryptographicCapital";

const StyledWebsite = styled.div({
  marginLeft: "8rem",
  marginRight: "8rem",
});

function App() {
  const { theme, themeToggler } = useThemeMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;
  useEffect(() => {
    document.body.style.backgroundColor = themeMode.background;
  }, [theme]);
  return (
    <StyledWebsite>
      <ThemeProvider theme={themeMode}>
        <Router>
          <NavBar
            theme={theme}
            themeToggler={themeToggler}
            themeMode={themeMode}
          />
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  theme={theme}
                  themeToggler={themeToggler}
                  themeMode={themeMode}
                />
              }
            />
            <Route
              path="/about"
              element={
                <About
                  theme={theme}
                  themeToggler={themeToggler}
                  themeMode={themeMode}
                />
              }
            />
            <Route path="/skills" element={<Skills />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/swordfighting" element={<SwordFightingHome />} />
            <Route path="/swordfighting/gear" element={<SwordGear />} />
            <Route path="/swordfighting/forms" element={<SwordForms />} />
            <Route
              path="/cryptographiccapital"
              element={<CryptographicCapital />}
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </StyledWebsite>
  );
}

export default App;
