export interface ThemeProps {
  background: string;
  primary: string;
  primaryHover: string;
  complimentary: string;
  contrast: string;
  text: string;
}

export const darkTheme: ThemeProps = {
  background: "#485F68",
  primary: "#F0E08E",
  primaryHover: "#CDC07C",
  complimentary: "#FFDE38",
  contrast: "#3E36FF",
  text: "var(--dark-text)",
};

export const lightTheme: ThemeProps = {
  background: "#F6F6F6",
  primary: "#5B436C",
  primaryHover: "#7C5A95",
  complimentary: "#AC38FF",
  contrast: "#E7FF36",
  text: "var(--light-text)",
};
