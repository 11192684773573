import SideBar from "../../../components/navbar/sideBar";
import styled from "@emotion/styled";

const MainPage = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  paddingTop: ".5rem",
  position: "absolute",
  height: "100%",
  // background: "white",
});

const Page = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  marginTop: "-.5rem",
  // marginLeft: "20rem",
  // background: "#FFFFFF",
  justifyContent: "center",
  alignItems: "center",
});

const SwordForms = () => {
  return (
    <div>
      <div>
        <MainPage>
          <SideBar />
          <Page>
            <p>Sword Forms Page</p>
          </Page>
        </MainPage>
      </div>
    </div>
  );
};

export default SwordForms;
