import React from "react";
import { Link } from "react-router-dom";
import { GiBattleGear, GiHighKick } from "react-icons/gi";
import { RiAncientGateFill } from "react-icons/ri";
import styled from "@emotion/styled";
import NavBar from "./NavBar";

const SideBox = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100px",
  marginTop: "-.5rem",
  height: "100vh",
  padding: "1rem",
  boxSizing: "border-box",
  background: "#444",
  position: "absolute",
});

const StyledLink = styled(Link)({
  fontSize: "xxx-large",
  margin: ".5rem",
  "&:hover": {
    color: "#e70f0f",
  },
});

const SideBar = () => {
  return (
    <SideBox>
      <StyledLink to="/swordfighting">
        <RiAncientGateFill />
      </StyledLink>
      <StyledLink to="/swordfighting/gear">
        <GiBattleGear />
      </StyledLink>
      <StyledLink to="/swordfighting/forms">
        <GiHighKick />
      </StyledLink>
    </SideBox>
  );
};

export default SideBar;
