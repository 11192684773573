import React, { useState } from "react";
import "../cryptographicCapital/CryptographicCapital.css";
import aPigpen from "./assets/a.png";
import bPigpen from "./assets/b.png";
import cPigpen from "./assets/c.png";
import dPigpen from "./assets/d.png";
import ePigpen from "./assets/e.png";
import fPigpen from "./assets/f.png";
import gPigpen from "./assets/g.png";
import hPigpen from "./assets/h.png";
import iPigpen from "./assets/i.png";
import jPigpen from "./assets/j.png";
import kPigpen from "./assets/k.png";
import lPigpen from "./assets/l.png";
import mPigpen from "./assets/m.png";
import nPigpen from "./assets/n.png";
import oPigpen from "./assets/o.png";
import pPigpen from "./assets/p.png";
import qPigpen from "./assets/q.png";
import rPigpen from "./assets/r.png";
import sPigpen from "./assets/s.png";
import tPigpen from "./assets/t.png";
import uPigpen from "./assets/u.png";
import vPigpen from "./assets/v.png";
import wPigpen from "./assets/w.png";
import xPigpen from "./assets/x.png";
import yPigpen from "./assets/y.png";
import zPigpen from "./assets/z.png";

const CryptographicCapital = () => {
  const [plaintext, setPlaintext] = useState("");
  // const [ciphertext, setCiphertext] = useState();

  // const handleChange = (event: any) => {
  //   setPlaintext(event.target.value);
  //
  //   // setCiphertext();
  //   console.log("value is:", event.target.value);
  // };

  // function handleClick() {
  //   console.log("Encrypt button clicked");
  //
  //   // let unencryptedText: any;
  //   // unencryptedText = document.getElementById("input-text").value;
  //   // const encryptedText = pigpenCipher(unencryptedText);
  //   // const resultField = document.getElementById("result");
  //   //
  //   // resultField.innerHTML = encryptedText;
  //
  //   // @ts-ignore
  // }

  const handlePigpenClick = (event: any) => {
    console.log(event.currentTarget.id);
    if (event.currentTarget.id === "a") {
      const addString = () => setPlaintext(plaintext + "A");
      addString();
    } else if (event.currentTarget.id === "b") {
      const addString = () => setPlaintext(plaintext + "B");
      addString();
    } else if (event.currentTarget.id === "c") {
      const addString = () => setPlaintext(plaintext + "C");
      addString();
    } else if (event.currentTarget.id === "d") {
      const addString = () => setPlaintext(plaintext + "D");
      addString();
    } else if (event.currentTarget.id === "e") {
      const addString = () => setPlaintext(plaintext + "E");
      addString();
    } else if (event.currentTarget.id === "f") {
      const addString = () => setPlaintext(plaintext + "F");
      addString();
    } else if (event.currentTarget.id === "g") {
      const addString = () => setPlaintext(plaintext + "G");
      addString();
    } else if (event.currentTarget.id === "h") {
      const addString = () => setPlaintext(plaintext + "H");
      addString();
    } else if (event.currentTarget.id === "i") {
      const addString = () => setPlaintext(plaintext + "I");
      addString();
    } else if (event.currentTarget.id === "j") {
      const addString = () => setPlaintext(plaintext + "J");
      addString();
    } else if (event.currentTarget.id === "k") {
      const addString = () => setPlaintext(plaintext + "K");
      addString();
    } else if (event.currentTarget.id === "l") {
      const addString = () => setPlaintext(plaintext + "L");
      addString();
    } else if (event.currentTarget.id === "m") {
      const addString = () => setPlaintext(plaintext + "M");
      addString();
    } else if (event.currentTarget.id === "n") {
      const addString = () => setPlaintext(plaintext + "N");
      addString();
    } else if (event.currentTarget.id === "o") {
      const addString = () => setPlaintext(plaintext + "O");
      addString();
    } else if (event.currentTarget.id === "p") {
      const addString = () => setPlaintext(plaintext + "P");
      addString();
    } else if (event.currentTarget.id === "q") {
      const addString = () => setPlaintext(plaintext + "Q");
      addString();
    } else if (event.currentTarget.id === "r") {
      const addString = () => setPlaintext(plaintext + "R");
      addString();
    } else if (event.currentTarget.id === "s") {
      const addString = () => setPlaintext(plaintext + "S");
      addString();
    } else if (event.currentTarget.id === "t") {
      const addString = () => setPlaintext(plaintext + "T");
      addString();
    } else if (event.currentTarget.id === "u") {
      const addString = () => setPlaintext(plaintext + "U");
      addString();
    } else if (event.currentTarget.id === "v") {
      const addString = () => setPlaintext(plaintext + "V");
      addString();
    } else if (event.currentTarget.id === "w") {
      const addString = () => setPlaintext(plaintext + "W");
      addString();
    } else if (event.currentTarget.id === "x") {
      const addString = () => setPlaintext(plaintext + "X");
      addString();
    } else if (event.currentTarget.id === "y") {
      const addString = () => setPlaintext(plaintext + "Y");
      addString();
    } else if (event.currentTarget.id === "z") {
      const addString = () => setPlaintext(plaintext + "Z");
      addString();
    }
  };
  //
  // function handleRegionClick(id) {
  //   console.log(id);
  // }

  return (
    <div>
      {/*<div>*/}
      {/*  <input*/}
      {/*    type="text"*/}
      {/*    id="plaintext"*/}
      {/*    onChange={handleChange}*/}
      {/*    value={plaintext}*/}
      {/*  />*/}
      {/*  <button onClick={handleClick}>Encrypt</button>*/}
      {/*</div>*/}
      <div className="container">
        <div className="grid">
          <div className="grid__item">
            <button id="a" onClick={handlePigpenClick}>
              <img src={aPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="b" onClick={handlePigpenClick}>
              <img src={bPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="c" onClick={handlePigpenClick}>
              <img src={cPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="d" onClick={handlePigpenClick}>
              <img src={dPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="e" onClick={handlePigpenClick}>
              <img src={ePigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="f" onClick={handlePigpenClick}>
              <img src={fPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="g" onClick={handlePigpenClick}>
              <img src={gPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="h" onClick={handlePigpenClick}>
              <img src={hPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="i" onClick={handlePigpenClick}>
              <img src={iPigpen} alt={""} />
            </button>
          </div>
        </div>
        <div className="grid">
          <div className="grid__item">
            <button id="j" onClick={handlePigpenClick}>
              <img src={jPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="k" onClick={handlePigpenClick}>
              <img src={kPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="l" onClick={handlePigpenClick}>
              <img src={lPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="m" onClick={handlePigpenClick}>
              <img src={mPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="n" onClick={handlePigpenClick}>
              <img src={nPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="o" onClick={handlePigpenClick}>
              <img src={oPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="p" onClick={handlePigpenClick}>
              <img src={pPigpen} alt={""} />
            </button>
          </div>
          <div className="grid__item">
            <button id="q" onClick={handlePigpenClick}>
              <img src={qPigpen} alt={""} />
            </button>
          </div>
          <div>
            <button id="r" onClick={handlePigpenClick}>
              <img src={rPigpen} alt={""} />
            </button>
          </div>
        </div>
        <div className="altGrid">
          <div className="item">
            <button id="s" onClick={handlePigpenClick}>
              <img src={sPigpen} alt={""} />
            </button>
          </div>
          <div className="item">
            <button id="t" onClick={handlePigpenClick}>
              <img src={tPigpen} alt={""} />
            </button>
          </div>
          <div className="item">
            <button id="u" onClick={handlePigpenClick}>
              <img src={uPigpen} alt={""} />
            </button>
          </div>
          <div className="item">
            <button id="v" onClick={handlePigpenClick}>
              <img src={vPigpen} alt={""} />
            </button>
          </div>
        </div>
        <div className="altGrid">
          <div className="item">
            <button id="w" onClick={handlePigpenClick}>
              <img src={wPigpen} alt={""} />
            </button>
          </div>
          <div className="item">
            <button id="x" onClick={handlePigpenClick}>
              <img src={xPigpen} alt={""} />
            </button>
          </div>
          <div className="item">
            <button id="y" onClick={handlePigpenClick}>
              <img src={yPigpen} alt={""} />
            </button>
          </div>
          <div className="item">
            <button id="z" onClick={handlePigpenClick}>
              <img src={zPigpen} alt={""} />
            </button>
          </div>
        </div>
        <div>
          <div>Plaintext:{plaintext}</div>
          {/*<div>Ciphertext:{ciphertext}</div>*/}
        </div>
      </div>
    </div>
  );
};

export default CryptographicCapital;
