import skillQuest from "../components/assets/skills.png";
import React from "react";

const Skills = () => {
  return (
    <div>
      <h1
        style={{
          color: "whitesmoke",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Skills Page
      </h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={skillQuest} alt="" />
      </div>
    </div>
  );
};

export default Skills;
