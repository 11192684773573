import React from "react";
import { Link } from "react-router-dom";
import { GiMushroomHouse, GiScrollQuill } from "react-icons/gi";
import { FaHatWizard } from "react-icons/fa";
import { BiNetworkChart } from "react-icons/bi";
import styled from "@emotion/styled";
import TogglerButton from "../ToggleButton";

const StickyNav = styled.div({
  height: "75px",
  textAlign: "center",
  display: "flex",
  alignSelf: "flex-end",
  marginBottom: ".2rem",
  "& p": { width: "100%" },
});

const NavBox = styled.div({
  display: "flex",
  marginTop: ".5rem",
  marginRight: ".5rem",
});

const StyledLink = styled(Link)({
  color: "inherit",
  fontSize: "xxx-large",
  margin: ".5rem",
  "&:hover": {
    color: "red",
  },
});

const StyledHeader = styled.p({
  fontFamily: "Macondo, cursive",
  fontSize: "xx-large",
  marginTop: "1.5rem",
  textAlign: "start",
  marginLeft: ".5rem",
  fontWeight: "bold",
});

const NavBar = (props: {
  theme: string;
  themeMode: any;
  themeToggler: () => void;
}) => {
  const { theme, themeToggler, themeMode } = props;
  return (
    <StickyNav style={{ color: themeMode.primary }}>
      <StyledHeader>Road to Wizardry</StyledHeader>
      <NavBox>
        <StyledLink to="/">
          <GiMushroomHouse />
        </StyledLink>
        <StyledLink to="/about">
          <FaHatWizard />
        </StyledLink>
        <StyledLink to="/skills" style={{ pointerEvents: "none" }}>
          <BiNetworkChart />
        </StyledLink>
        <StyledLink to="/blog" style={{ pointerEvents: "none" }}>
          <GiScrollQuill />
        </StyledLink>
      </NavBox>
      <TogglerButton themeToggler={themeToggler} />
    </StickyNav>
  );
};

export default NavBar;
