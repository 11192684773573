import NavBar from "../components/navbar/NavBar";

const Blog = () => {
  return (
    <div>
      <p>Blog Page</p>
    </div>
  );
};

export default Blog;
