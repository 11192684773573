import { HiMoon } from "react-icons/hi";
import { FaSun } from "react-icons/fa";
import { Container, Icons } from "./styles";
import styled from "@emotion/styled";

const StyledLink = styled("span")({
  fontSize: "xxx-large",
  justifyContent: "start",
  "&:hover": {
    color: "#e70f0f",
  },
});

interface ThemeTogglerProps {
  themeToggler: () => void;
}

function TogglerButton({ themeToggler }: ThemeTogglerProps) {
  return (
    <Container>
      <label htmlFor="checkbox" className="switch">
        <input
          id="checkbox"
          type="checkbox"
          onClick={themeToggler}
          onChange={() => false}
          checked={window.localStorage.getItem("theme") === "light"}
        />
        <Icons>
          {window.localStorage.getItem("theme") !== "light" ? (
            <StyledLink>
              <HiMoon />
            </StyledLink>
          ) : (
            <StyledLink>
              <FaSun />
            </StyledLink>
          )}
        </Icons>
      </label>
    </Container>
  );
}

export default TogglerButton;
