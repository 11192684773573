import homePhoto from "../components/assets/homePhoto.png";
import "./home.css";
import { BsFillPuzzleFill } from "react-icons/bs";
import { GiKatana, GiScrollQuill } from "react-icons/gi";
import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)({
  color: "inherit",
  fontSize: "xxx-large",
  margin: ".5rem",
  "&:hover": {
    color: "red !Important",
  },
});

const Home = (props: {
  theme: string;
  themeMode: any;
  themeToggler: () => void;
}) => {
  const { theme, themeToggler, themeMode } = props;
  return (
    <div>
      <div>
        <p>Home Page</p>
        <div id="home" className="home-container">
          <div className="logo">
            <a href={"/cryptographiccapital"}>
              <img
                src={homePhoto}
                alt=""
                style={{ height: "62%", width: "65%" }}
              />
            </a>
            <br />
          </div>
        </div>
        {/*</ThemeProvider>*/}
        {/*</ThemeContext>*/}
      </div>
      <div className="container">Projects</div>
      <StyledLink to="/swordfighting" style={{ color: themeMode.primary }}>
        <GiKatana />
      </StyledLink>

      <StyledLink
        to="/cryptographiccapital"
        style={{ color: themeMode.primary }}
      >
        <BsFillPuzzleFill />
      </StyledLink>
    </div>
  );
};

export default Home;
